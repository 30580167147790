function openPrintWin(tempAry){
        var printWin = window.open('', 'printWin', 'width=1000,height=1000,top=0,left=0,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no');
        if (printWin) {
            printWin.document.write('<style>body{font-size: 20px;}</style>');
            printWin.document.write("<style>@page {size: A4;font} table{font-size:14pt;} @media print{.no-print{display: none !important;}}</style>")
            printWin.document.write("<button onclick='window.print();window.close()' class='no-print' id='start'>打 印</button>")
            printWin.document.write("<button onclick='window.close()' class='no-print' id='start'>关 闭</button>")
            printWin.document.write('<div style="display:flex;flex-wrap:wrap;"><div style="flex: 0 0 33%; margin: 0 0 15px 0">');
            printWin.document.write(tempAry.join('</div><div style="flex: 0 0 33%; margin: 0 0 15px 0">'));
            printWin.document.write('</div></div>');
        } else {
            alert('无法打开新窗口，请检查浏览器设置或尝试其他操作。');
        }
}
// Ebbinghaus 
const REVIEW_DAYS = [
    0, 
    30 * 60 * 1000, 
    1 * 24 * 60 * 60 * 1000, 
    2 * 24 * 60 * 60 * 1000, 
    4 * 24 * 60 * 60 * 1000, 
    7 * 24 * 60 * 60 * 1000, 
    15 * 24 * 60 * 60 * 1000, 
    30 * 24 * 60 * 60 * 1000, 
    90 * 24 * 60 * 60 * 1000, 
    180 * 24 * 60 * 60 * 1000
]
function getNextReviewDate(reviewCnt: number): number {
    if (reviewCnt >= REVIEW_DAYS.length) {
        return new Date('2099/12/31').getTime();
    }
    return new Date().getTime() + REVIEW_DAYS[reviewCnt];
}

export {
    openPrintWin,
    getNextReviewDate
}