import React from "react";
//pinyin4js@1.3.16 or latest
import pinyin4js from 'pinyin4js';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { useState } from "react";
import type { Schema, Template, Font } from '@pdfme/common';
import { generate } from '@pdfme/generator';
import { text, image, barcodes } from '@pdfme/schemas';
import { getNextReviewDate } from "../common/utils.tsx";


const X_STEP = 65;
const Y_STEP = 15;


const font: Font = {
    "SimSun": {
        data: 'http://localhost:3000/SIMSUNB.TTF',
        fallback: true,
    },
};

const fieldTemplate = {
    "name": "field1",
    "type": "text",
    "content": "",
    "position": {
        "x": 10,
        "y": 10
    },
    "width": 60,
    "height": 10,
    fontName: "SimSun",
}


function Services() {
    // use useState to get input value
    const [inputValue, setInputValue] = useState('你*好 大家');
    const [outputValues, setOutputValues] = useState<string[]>([]);
    const [resultValue, setResultValue] = useState('');

    function print() {

        let template = {
            "schemas": [
                []
            ],
            "basePdf": {
                "width": 210,
                "height": 297,
                "padding": [
                    10,
                    10,
                    10,
                    10
                ]
            },
            "pdfmeVersion": "5.2.11"
        };
        const MAX_COLUMNS = 3;
        const MAX_ROWS = 30;
        const FIELD_LIMIT = 20;
        let row = 0, colomn = 0;
        let inputs: {}[] = [];
        let oneInput = {};
        outputValues.forEach((value, index) => {
            if (value.length > FIELD_LIMIT && colomn > 1) {
                colomn = 0;
                row++;
            }

            if (row > MAX_ROWS) {
                inputs.push(oneInput);
                oneInput = {}
            }
            // clone fieldTemplate
            const field: Schema = JSON.parse(JSON.stringify(fieldTemplate));
            field.position.y = row * Y_STEP + template.basePdf.padding[0];
            field.position.x = colomn * X_STEP + template.basePdf.padding[3];
            field.name = "field" + row + "_" + colomn;
            template.schemas[0].push(field);
            oneInput[field.name] = value;
            // calculate next position
            colomn++;
            if (colomn >= MAX_COLUMNS) {
                row++;
                colomn = 0;
            }
            if (row >= MAX_ROWS) {
                oneInput = {}
            }
        })
        inputs.push(oneInput);
        // generate({ template, inputs, 
        //     plugins: {
        //         text,
        //         image,
        //         qrcode: barcodes.qrcode,
        //       },


        //     options: { font } }).then((pdf) => {
        //     console.log(pdf);
        //     // Browser
        //     const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
        //     window.open(URL.createObjectURL(blob));
        // });
    }

    function generatePinyin(str) {
        let tempAry: string[] = [];
        str.split(' ').forEach(function (item) {
            if (item == '') return;
            if (item.length > 0) {
                var pinYinStr = pinyin4js.convertToPinyinString(item.replaceAll('*', ''), ' ', pinyin4js.WITH_TONE_MARK).split(' ');
                var pinYinIndex = 0;
                var shouldOutput = true;
                var itemStr = '';
                // loop every charactors in the string
                for (var i = 0; i < item.length; i++) {
                    if (item.charAt(i) == '*') {
                        shouldOutput = false;
                    } else {
                        if (shouldOutput) {
                            itemStr += pinyin4js.convertToPinyinString(pinYinStr[pinYinIndex++], ' ', pinyin4js.WITH_TONE_MARK) + "(　　)";
                        } else {
                            itemStr += item.charAt(i);
                            pinYinIndex++;
                            shouldOutput = true;
                        }
                    }
                }
                tempAry.push(itemStr);
            }
        });
        setOutputValues(tempAry);
        setResultValue(inputValue);
        console.log(outputValues);
        var printWin = window.open('', 'printWin', 'width=1000,height=1000,top=0,left=0,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no');
        if (printWin) {
            printWin.document.write('<style>body{font-size: 20px;}</style>');
            printWin.document.write("<style>@page {size: A4;font} table{font-size:14pt;} @media print{.no-print{display: none !important;}}</style>")
            printWin.document.write("<button onclick='window.print();window.close()' class='no-print' id='start'>打 印</button>")
            printWin.document.write("<button onclick='window.close()' class='no-print' id='start'>关 闭</button>")
            printWin.document.write('<div style="display:flex;flex-wrap:wrap;"><div style="flex: 0 0 33%; margin: 0 0 15px 0">');
            printWin.document.write(tempAry.join('</div><div style="flex: 0 0 33%; margin: 0 0 15px 0">'));
            printWin.document.write('</div></div>');
        } else {
            alert('无法打开新窗口，请检查浏览器设置或尝试其他操作。');
        }
        print();
    }

    function saveRecord(str) {

        let remoteRecord = JSON.parse(localStorage.getItem('chineseWordsRemote') || '[]');
        let newWords : any[] = [];
        str.split(' ').forEach((item) => {
            if (item.length > 0) {
                let index = remoteRecord.findIndex(word => word.word === item.replaceAll('X', ''));
                if (index !== -1) {
                    remoteRecord[index].reviewCnt += 1;
                    remoteRecord[index].nextReviewDate = getNextReviewDate(remoteRecord[index].reviewCnt);
                    remoteRecord[index].wrongCnt = item.indexOf('X') > -1 ? remoteRecord[index].wrongCnt + 1 : remoteRecord[index].wrongCnt;
                    remoteRecord[index].lastReviewDate = new Date().getTime();
                }
                else {
                    newWords.push(
                        {
                            word: item.replaceAll('X', ''),
                            addDate: new Date().getTime(),
                            lastReviewDate: new Date().getTime(),
                            wrongCnt: item.indexOf('X') > -1 ? 1 : 0,
                            reviewCnt: 0,
                            nextReviewDate: getNextReviewDate(0),
                        });
                }

            }
        })
        
        localStorage.setItem('chineseWordsRemote', JSON.stringify(remoteRecord.concat(newWords)));
    }

    // more detail methods in test
    // WITH_TONE_NUMBER--数字代表声调，WITHOUT_TONE--不带声调，WITH_TONE_MARK--带声调
    return (
        <section id="pinyinwriting" className="services" >
            <div className="section-title">
                <h2>字词默写</h2>
                <p></p>
            </div>
            <div style={
                { height: '540px', width: '90%', margin: '0 auto' }
            }>
                <TextField style={styles.textField} onChange={(e) => { setInputValue(e.target.value) }}
                    label="听写汉字，以空格分隔。不需要默写的字前面加“*”号，如 *你好 大家，将生成 你,hǎo, ,dà,jiā"
                    multiline
                    defaultValue={inputValue}
                    rows={4}
                />
                <div style={
                    { height: '', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }
                }>
                    <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={() => { generatePinyin(inputValue) }}> 生成拼音 </Button>
                </div>
                <TextField style={styles.textField} onChange={(e) => { setResultValue(e.target.value) }}
                    label="复制听写汉字，以空格分隔。写作的字前面加“*”号，如 X你*好 大*家，将自动记录错字。"
                    multiline
                    defaultValue={resultValue}
                    value={resultValue}
                    
                    rows={4}
                />
                <div style={
                    { height: '', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }
                }>
                    <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={() => { saveRecord(resultValue) }}
                        disabled={resultValue == ''}> 保存记录 (制作中）</Button>
                </div>

            </div>
        </section>
    )
}
export default Services;

// styles
const styles = {
    // 100% width mui text field and two rows height
    textField: {
        width: '100%',
        marginTop: '10px',

    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
    },
    item: {
        flex: '0 0 25%',
    },
    printContainer: {
        display: 'flex',
        marginTop: '10px',
        lexWrap: 'wrap',
        overflow: 'scroll',
        maxHeight: '80%',
    }
}