import React from "react";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import PlayCircle from '@mui/icons-material/Send';
import { useState, useEffect, useRef } from "react";
import { getSettings, Page as Setting } from '../common/setting.tsx';
import { getNextReviewDate } from "../common/utils.tsx";
import { uploadContent, isRemoteAvaliable } from '../common/ossUtils.tsx';

const WORD_COUNT_PERTEST = 10;

let index = -1;
let words: any[] = [];
let currentWord = '';
let failWords: any[] = [];

function speakWord(str) {
    window.speechSynthesis.speak(new SpeechSynthesisUtterance(str));
}

function mergeWords(data, temp) {

    // most old reviewed 15 words
    // merge words with localStorage, merge key is word, delete merged elements from temp
    data = data.map(inword => {

        let index = temp.findIndex(tempWord => tempWord.word === inword.word);
        if (index !== -1) {
            inword.addDate = temp[index].addDate;
            inword.lastReviewDate = temp[index].lastReviewDate;
            inword.wrongCnt = temp[index].wrongCnt;
            inword.reviewCnt = temp[index].reviewCnt;
            inword.nextReviewDate = temp[index].nextReviewDate;
        }
        return inword;
    })
    temp.forEach(tempWord => {
        let index = data.findIndex(inword => inword.word === tempWord.word);
        if (index === -1) {
            data.push(tempWord);
        }
    })
    return data;
}

function saveLocalResult(status) {
    localStorage.setItem('status', JSON.stringify(status));
    localStorage.setItem('statusDate', new Date().getTime().toString());
}

function saveRemoteWords(words) {
    let settings = getSettings();
    let remoteWords;
    remoteWords = JSON.parse(localStorage.getItem('remoteWords') || '[]');
    localStorage.setItem('remoteWords', JSON.stringify(mergeWords(remoteWords, words)));
    if (isRemoteAvaliable()) {
        return uploadContent((settings.domain?(settings.domain+'/'):'') + 'remoteWords.json', JSON.stringify(remoteWords)).then(() => {
            alert('保存成功');
        });
    } else {

    }
}



function Page() {
    const inputRef = React.useRef(null);
    const [inputValue, setInputValue] = useState('Abc...');
    const [word, setWord] = useState('Press Start!');
    const [desc, setDesc] = useState('');
    const [isCorrect, setIsCorrect] = useState(true);
    const [correctCnt, setCorrectCnt] = useState(0);
    const [wrongCnt, setWrongCnt] = useState(0);
    const [totalCnt, setTotalCnt] = useState(0);
    function nextWord() {
        index++;
        if (index < words.length) {
            currentWord = words[index].word;
            setWord('');
            setInputValue('');
            setDesc(words[index].desc)
            speakWord(currentWord);
        } else if (failWords.length > 0) {
            currentWord = failWords[0].word;
            setWord('');
            setInputValue('');
            setDesc(failWords[0].desc)
            speakWord(currentWord);
        } else {
            setWord('Finished!');
            setDesc('')
            setInputValue('');
            index = -1;
            saveRemoteWords(words);
            localStorage.removeItem('status');
        }
        if (inputRef.current)
            inputRef.current.getElementsByTagName("input")[0].focus();
    }


    function checkWord() {
        let newWord = currentWord;
        setWord(newWord);
        let innerIndex = index;

        // match currentWord with user input
        if (failWords.findIndex(word => word.word == currentWord) > -1) {
            failWords.splice(failWords.findIndex(word => word.word == currentWord), 1);
            innerIndex = words.findIndex(word => word.word == currentWord);
        }
        let newIsCurrect = isCorrect;
        let newCorrectCnt = correctCnt;
        let newWrongCnt = wrongCnt;

        if (currentWord == inputValue) {
            newIsCurrect = true;
            setIsCorrect(newIsCurrect);
            newCorrectCnt = correctCnt + 1;
            setCorrectCnt(newCorrectCnt);

            words[innerIndex].reviewCnt = (words[innerIndex].reviewCnt || 0) + 1;
            words[innerIndex].lastReviewDate = new Date().getTime();
            words[innerIndex].nextReviewDate = getNextReviewDate(
                Math.max(0, (words[innerIndex].reviewCnt || 0)
                    - Math.ceil(
                        (words[innerIndex].wrongCnt || 0) / 2
                    )
                )
            );

        } else {
            newIsCurrect = false;
            setIsCorrect(false);
            newWrongCnt = wrongCnt + 1;
            setWrongCnt(newWrongCnt);

            words[innerIndex].wrongCnt = (words[innerIndex].wrongCnt || 0) + 1;

            failWords.push({
                word: currentWord,
                desc: words[innerIndex].desc
            });
        }
        speakWord(currentWord);
        currentWord = '';

        let status = {
            index: index,
            words: words,
            currentWord: currentWord,
            inputValue: inputValue,
            word: newWord,
            desc: desc,
            isCorrect: newIsCurrect,
            correctCnt: newCorrectCnt,
            wrongCnt: newWrongCnt,
            totalCnt: totalCnt
            , failWords: failWords
        }

        saveLocalResult(status);
    }


    const handleKeyDown = (event) => {
        if (index == -1) return
        if (event.key === 'Enter') {
            if (currentWord == '') {
                nextWord();
            }
            if (word == '') {
                checkWord();
            }
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    function resumeStatus(tempStr) {
        if (!tempStr) {
            return;
        }
        let status = JSON.parse(tempStr);
        index = status.index;
        words = status.words;
        currentWord = status.currentWord;
        failWords = status.failWords;
        setWord(status.word);
        setInputValue(status.inputValue);
        setDesc(status.desc);
        setIsCorrect(status.isCorrect);
        setCorrectCnt(status.correctCnt);
        setWrongCnt(status.wrongCnt);
        setTotalCnt(status.totalCnt);
    }

    function loadWords() {
        let settings = getSettings();
        if (!settings) return;
        if (!settings.databaseUrl) {
            alert('no database setting')
            return null;
        }
        return fetch(settings.databaseUrl
            + (settings.databaseUrl.endsWith('/') ? '' : '/')
            + (settings.domain ? (settings.domain + '/') : "")
            + "words.json", { cache: 'no-cache' })
            .then(response => response.json())
            .then(data => {
                if (!data || !Array.isArray(data)) return;
                if (localStorage.getItem('remoteWords')) {
                    let temp = JSON.parse(localStorage.getItem('remoteWords') || '[]');
                    return new Promise((resolve, reject) => { resolve(mergeWords(data, temp)) });
                } else {
                    return new Promise((resolve, reject) => { resolve(data) });
                }
            })
    }


    // 渲染完成
    function init() {
        setWord('Loading...');

        let tempStr = localStorage.getItem('status');
        if (tempStr) {
            resumeStatus(tempStr);
            return;
        }
        setCorrectCnt(0);
        setWrongCnt(0);

        loadWords()
            .then((data: any) => {
                if (!data || !Array.isArray(data)) return;

                const CURRENT_TIME = new Date().getTime();
                data = data.filter((item) => { return !item.nextReviewDate || item.nextReviewDate < CURRENT_TIME })
                data.sort((a, b) => {
                    if (a.nextReviewDate === null) {
                        a.nextReviewDate = new Date().getTime() - 1;
                    }
                    if (b.nextReviewDate === null) {
                        b.nextReviewDate = new Date().getTime() - 1;
                    }
                    return a.nextReviewDate - b.nextReviewDate;
                })
                let wordNum = 0;
                let weight = 0;
                let tempIndex = 0;
                while (weight < WORD_COUNT_PERTEST * 100 && tempIndex < data.length) {
                    wordNum++;
                    weight += 100 / ((data[tempIndex].reviewCnt || 0) + 1);
                    tempIndex++;
                }
                words = data.slice(0, wordNum);
                setTotalCnt(words.length);
                nextWord();
            }).catch((err) => {
                console.log(err);
                alert(err);
            });
    }

    function showWordList() {
        loadWords()
            .then((data: any) => {
                if (!data || !Array.isArray(data)) return;

                data.sort((a, b) => {
                    if (a.lastReviewDate === null) {
                        a.lastReviewDate = 0;
                    }
                    if (b.lastReviewDate === null) {
                        b.lastReviewDate = 0;
                    }
                    return b.lastReviewDate - a.lastReviewDate;
                })

                var printWin = window.open('', 'printWin', 'width=1000,height=1000,top=0,left=0,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no');
                if (printWin) {
                    printWin.document.write('<style>body{font-size: 20px;}</style>');
                    printWin.document.write("<style>@page {size: A4;font} table{font-size:14pt;} @media print{.no-print{display: none !important;}}</style>")
                    printWin.document.write("<button onclick='window.print();window.close()' class='no-print' id='start'>打 印</button>")
                    printWin.document.write("<button onclick='window.close()' class='no-print' id='start'>关 闭</button>")
                    printWin.document.write('<div style="display:flex;flex-wrap:wrap;">');

                    printWin.document.write('<div style="flex: 0 0 20%; margin: 0 0 15px 0">');
                    printWin.document.write('Words');
                    printWin.document.write('</div>');
                    printWin.document.write(('<div style="flex: 0 0 20%; margin: 0 0 15px 0">'));
                    printWin.document.write('Description');
                    printWin.document.write(('</div>'));
                    printWin.document.write(('<div style="flex: 0 0 20%; margin: 0 0 15px 0">'));
                    printWin.document.write('Last Reviewed Date');
                    printWin.document.write(('</div>'));
                    printWin.document.write(('<div style="flex: 0 0 20%; margin: 0 0 15px 0">'));
                    printWin.document.write('Reviewed Times');
                    printWin.document.write(('</div>'));
                    printWin.document.write(('<div style="flex: 0 0 20%; margin: 0 0 15px 0">'));
                    printWin.document.write(('</div>'));
                    data.forEach((item, index) => {
                        printWin.document.write('<div style="flex: 0 0 20%; margin: 0 0 15px 0">');
                        printWin.document.write(item.word);
                        printWin.document.write('</div>');
                        printWin.document.write(('<div style="flex: 0 0 20%; margin: 0 0 15px 0">'));
                        printWin.document.write(item.desc);
                        printWin.document.write(('</div>'));
                        printWin.document.write(('<div style="flex: 0 0 20%; margin: 0 0 15px 0">'));
                        printWin.document.write(new Date(item.lastReviewDate).toLocaleString());
                        printWin.document.write(('</div>'));
                        printWin.document.write(('<div style="flex: 0 0 20%; margin: 0 0 15px 0">'));
                        printWin.document.write(item.reviewCnt || 0);
                        printWin.document.write(('</div>'));
                        printWin.document.write(('<div style="flex: 0 0 20%; margin: 0 0 15px 0">'));
                        printWin.document.write(('</div>'));
                    })
                    printWin.document.write('</div></div>');
                } else {
                    alert('无法打开新窗口，请检查浏览器设置或尝试其他操作。');
                }
            });
    }
    return (
        <section id="spelling" className="services" >
            <div className="section-title">
                <h2>单词听写</h2>
                <p></p>
            </div>

            <div style={
                { height: '', display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end', marginRight: '20px' }
            }>
                总数：{totalCnt}  正确:{correctCnt} 错误：{wrongCnt}
            </div>
            <div style={
                { height: '540px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }
            }>
                {index >= 0 && word != '' ? (isCorrect ? (<div style={{ color: 'green' }}>Yes！</div>) : (<div style={{ color: 'red' }}>No!</div>)) : null}
                <div style={{ ...styles.wordField }}> {word}</div>
                <div style={{ ...styles.wordField }}> {desc}</div>
                {index < 0 ? (<Button variant="contained" onClick={init}>Start</Button>) : <Button variant="contained" onClick={() => speakWord(currentWord)}>Play </Button>}
                <TextField id="spellingInput" label="How to spell it?" variant="standard" style={styles.textField} onKeyDown={handleKeyDown} value={inputValue}
                    onChange={handleInputChange} ref={inputRef} autoComplete="off"
                />
                {index < 0 ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <a href="#" onClick={showWordList}> Show all Words</a>
                        <Setting></Setting>
                    </div>) : null}
            </div>

        </section>
    )
}
export default Page;

// styles
const styles = {
    wordField: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    button: {
        matchMedia: '(max-width: 600px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px'
    },
    textField: {
        width: '200px',
        margin: '20px',
        TextAlign: 'center'
        // text align center


    },
}