
var client;
var init = function () {
    if(client) return;
    let tempStr = localStorage.getItem('settings');
    if (!tempStr) {
        alert('请先设置');
        return false;
    }
    let settings = JSON.parse(tempStr);
    if (!settings.accessKeyId || !settings.accessKeySecret || !settings.bucket || !settings.region) {
        console.log('无远程设定，仅本地存储');
        return false;
    }

    client = new OSS({
        region: settings.region,
        accessKeyId: settings.accessKeyId,
        accessKeySecret: settings.accessKeySecret,
        bucket: settings.bucket
    });
};

var isRemoteAvaliable = function () {
    init();
    return client !== undefined;
};

var uploadContent = function (fileName, content) {
    if (!isRemoteAvaliable()) return new Promise(()=> false);
    return client.put(fileName, new OSS.Buffer(content));
};

var listFiles = function (client) {
    if (!isRemoteAvaliable()) return;

    return client.list({
        'max-keys': 100
    })
};

export { uploadContent, listFiles, isRemoteAvaliable };